import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_header = _resolveComponent("ion-header")
  const _component_ion_label = _resolveComponent("ion-label")
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")
  const _component_ion_segment = _resolveComponent("ion-segment")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")
  const _component_ion_fab = _resolveComponent("ion-fab")
  const _component_ion_loading = _resolveComponent("ion-loading")
  const _component_jn_doc = _resolveComponent("jn-doc")
  const _component_ion_list = _resolveComponent("ion-list")
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_segment, {
        scrollable: "",
        mode: "md",
        color: "tertiary",
        value: _ctx.inEvidenzaIndex[0],
        onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeType($event)))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.inEvidenza, (tag, index) => {
            return (_openBlock(), _createBlock("div", { key: index }, [
              _createVNode(_component_ion_segment_button, {
                mode: "md",
                value: _ctx.inEvidenzaIndex[index]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tag.label), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"])
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab, {
            slot: "fixed",
            vertical: "bottom",
            horizontal: "end"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                color: "secondary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openSearchParams()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { name: "search" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_loading, {
            "is-open": _ctx.loading,
            cssClass: "loading",
            message: "Caricamento...",
            duration: _ctx.timeout
          }, null, 8, ["is-open", "duration"]),
          (_ctx.docs)
            ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.docs, (n) => {
                    return (_openBlock(), _createBlock("div", {
                      key: n,
                      lines: "none"
                    }, [
                      _createVNode(_component_jn_doc, {
                        doc: n,
                        showAbstract: false
                      }, null, 8, ["doc"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadDocuments($event))),
            threshold: "100px",
            id: "infinite-scroll",
            disabled: _ctx.isDisabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "bubbles",
                "loading-text": "Caricamento..."
              })
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}